/* -----------------------------------------------------------------------------
 *
 * Regions: Header
 *
 * ----------------------------------------------------------------------------- */


/* -----------------------------------------------------------------------------
 * Site Header
 * ----------------------------------------------------------------------------- */

.site-header {
  position: sticky;
  z-index: 999;
  background-color: var( --color-torch-red );
  top: 0;

  /* to make the nav sticky at the red section */
  @media (--large) {
    top: -37px;
  }

  & > .l-container {
    max-width: none;
    padding: 10px 16px 12px;
    line-height: 1; /* resets and removes un-needed space */

    @media (--large) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: var( --grid-width );
      padding: 0 30px;
    }
  }
}

.site-header.navigation-open {
  @media (--large-max) {
    &::before {
      content: '';
      position: fixed;
      z-index: 8000;
      top: 95px; /* revert to 60px once Nav CTA test ends */
      right: 0;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      transition: opacity 225ms cubic-bezier( 0.4, 0, 0.2, 1 ) 0ms;
      opacity: 0.8;
      background-color: var( --color-midnight-express );
    }
  }
}

/* -----------------------------------------------------------------------------
 * Site Header Primary
 * ----------------------------------------------------------------------------- */

.site-header-primary {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* -----------------------------------------------------------------------------
 * Navigation Toggle Wrap
 * ----------------------------------------------------------------------------- */

.navigation-toggle-wrap {
  flex: 1;

  @media (--large) {
    flex: auto;
  }
}

/* -----------------------------------------------------------------------------
 * Navigation Toggle
 * ----------------------------------------------------------------------------- */

.navigation-toggle {
  padding-right: 0;
  padding-left: 0;
  border: transparent;
  background-color: transparent;
  cursor: pointer;

  @media (--large) {
    display: none;
  }
}

.navigation-toggle .navigation-toggle-icon,
.navigation-toggle .navigation-toggle-icon::before,
.navigation-toggle .navigation-toggle-icon::after {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 3px;
  margin-right: auto;
  margin-left: auto;
  transition: all 300ms ease-out;
  background-color: var( --color-white );
}

.navigation-toggle .navigation-toggle-icon {
  position: relative;
  margin-top: 12px;
  margin-bottom: 10px;

  &::before {
    top: -8px;
  }

  &::after {
    bottom: -8px;
  }
}

.navigation-toggle:hover .navigation-toggle-icon,
.navigation-toggle:focus .navigation-toggle-icon,
.navigation-toggle:hover .navigation-toggle-icon::before,
.navigation-toggle:focus .navigation-toggle-icon::before,
.navigation-toggle:hover .navigation-toggle-icon::after,
.navigation-toggle:focus .navigation-toggle-icon::after {
  background-color: var( --color-white );
}

/* -----------------------------------------------------------------------------
 * Navigation Toggle - Open Navigation
 * ----------------------------------------------------------------------------- */

.navigation-open.navigation-toggle .navigation-toggle-icon {
  background-color: transparent;

  &::before,
  &::after {
    top: 0;
  }

  &::before {
    transform: rotate( 45deg );
  }

  &::after {
    transform: rotate( -45deg );
  }
}

/* -----------------------------------------------------------------------------
 * Site Branding
 * ----------------------------------------------------------------------------- */

.site-branding {
  flex: 2;

  @media (--large) {
    flex: auto;
  }
}

.site-brand {
  display: flex;
  width: 28px;
  height: 28px;
  margin: 0 auto;
  text-decoration: none;

  @media (--large) {
    width: 48px;
    height: 48px;
  }
}


/* -----------------------------------------------------------------------------
 * Site Sign In
 * ----------------------------------------------------------------------------- */

.site-sign-in {
  flex: 1;
  max-width: 25%;
  display: flex;
  justify-content: flex-end;

  @media (--large) {
    display: none;
  }

  & > a:last-child {
    margin-left: 16px;
  }
}

.site-sign-in > a,
.site-sign-in > button {
  max-width: 100%;
  padding: 0;
  border: 0;
  color: var( --color-white );
  font-size: rem( 18px );
  font-weight: 500;
  line-height: lh( 14px, 18px );
  text-align: right;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;

  @media (--medium) {
    font-size: rem( 24px );
  }

  @media (--large) {
    font-size: rem( 16px );
  }
}

.site-sign-in > a:hover,
.site-sign-in > a:focus,
.site-sign-in > button:hover,
.site-sign-in > button:focus,
.site-sign-in > button:hover:enabled,
.site-sign-in > button:focus:enabled {
  background-color: transparent;
  color: var( --color-white );
}

.sign-in__name {
  pointer-events: none;

  @media (--large) {
    font-weight: 700;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (--large) {
    max-width: 200px;
  }
}
