:root {
	--icon-home-wifi-plus: '\e92e';
	--icon-price-circle: '\e92d';
	--icon-lock-wifi: '\e92c';
	--icon-wifi-shield-plus: '\e92b';
	--icon-close-bold: '\e91b';
	--icon-wrench: '\e916';
	--icon-router: '\e917';
	--icon-currency: '\e918';
	--icon-lock: '\e914';
	--icon-lock-double: '\e913';
	--icon-tech-support: '\e912';
	--icon-checkmark2: '\e90f';
	--icon-gift-card: '\e911';
	--icon-arrow-up-down2: '\e90e';
	--icon-chevron-down: '\e901';
	--icon-magnifying-glass2: '\e902';
	--icon-close2: '\e907';
	--icon-offers: '\e90c';
	--icon-star-outline: '\e928';
	--icon-smart-phone: '\e92a';
	--icon-info-outline: '\e924';
	--icon-speed-guage: '\e91c';
	--icon-cloud-upload-download: '\e90a';
	--icon-price-lock: '\e910';
	--icon-account: '\e915';
	--icon-wifi: '\e919';
	--icon-tv-lines: '\e926';
	--icon-unlock: '\e927';
	--icon-game-controller-2: '\e91d';
	--icon-fiber: '\e920';
	--icon-global-wifi: '\e921';
	--icon-laptop2: '\e922';
	--icon-play3: '\e923';
	--icon-home-wifi: '\e925';
	--icon-location-marker2: '\e91f';
	--icon-cloud-upload: '\e91e';
	--icon-phone-outline: '\e943';
	--icon-stopwatch: '\e942';
	--icon-magnifying-glass: '\e935';
	--icon-shopping-cart: '\e936';
	--icon-location-marker: '\e934';
	--icon-close: '\e90d';
	--icon-exclamation: '\e903';
	--icon-facebook: '\e904';
	--icon-info: '\e905';
	--icon-linkedin: '\e906';
	--icon-search: '\e908';
	--icon-twitter: '\e909';
	--icon-youtube: '\e90b';
	--icon-tv: '\e91a';
	--icon-play-circle: '\e900';
	--icon-twitter-x: '\e929';
	--icon-chevron-right: '\eaeb';
	--icon-chevron-left: '\eaec';
	--icon-chevron-down1: '\eaed';
	--icon-chevron-up: '\eaee';
	--icon-check-short: '\e940';
	--icon-checkmark1: '\ea10';
	--icon-arrow-left2: '\ea40';
	--icon-minus2: '\ea0b';
	--icon-instagram: '\ea92';
	--icon-chevron-right1: '\eaef';
	--icon-chevron-left1: '\eaf0';
	--icon-chevron-down11: '\eaf1';
	--icon-caret-right: '\f0da';
}
