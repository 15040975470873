/* -----------------------------------------------------------------------------
 *
 * Forms: Search
 *
 * ----------------------------------------------------------------------------- */

.search-form {
  position: relative;
  clear: both;
}

/* -----------------------------------------------------------------------------
 * Label
 * ----------------------------------------------------------------------------- */

.search-form label {
  display: block;
  margin-bottom: 0;
}

/* -----------------------------------------------------------------------------
 * Search Field
 * ----------------------------------------------------------------------------- */

.search-field {
  display: block;
  width: 100%;
}

/* -----------------------------------------------------------------------------
 * Search Submit
 * ----------------------------------------------------------------------------- */

.search-submit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
  padding: 0 12px;
  border: 0;
  background-color: transparent;
  color: var( --color-primary );
  font-size: 0;
  letter-spacing: 0;
  cursor: pointer;

  &::before {
    @mixin icomoon;

    content: var( --icon-search );
    display: inline-block;
    font-size: rem( 16px );
    vertical-align: middle;
  }
}
