/* -----------------------------------------------------------------------------
 *
 * Forms
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Label
 * ----------------------------------------------------------------------------- */

label {
  display: inline-block;
  margin-bottom: 4px;
  color: var( --color-white );
  font-family: var( --font-primary );
  font-weight: 700;
}

/* -----------------------------------------------------------------------------
 * Inputs
 * ----------------------------------------------------------------------------- */

input {
  display: block;
  width: 100%;
  height: 52px;
  padding: 0 0 0 15px;
  border: 1px solid var( --color-ghost-gray );
  background-color: var( --color-white );
  color: var( --color-midnight-express );

  &:focus {
    border-color: var( --color-tertiary );
  }
}

/* -----------------------------------------------------------------------------
 * Placeholders
 * ----------------------------------------------------------------------------- */

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  color: inherit;
  text-overflow: ellipsis;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: inherit;
  text-overflow: ellipsis;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  color: inherit;
  text-overflow: ellipsis;
}

/* -----------------------------------------------------------------------------
 * Forms: Input and select large.
 * ----------------------------------------------------------------------------- */

.input-large,
.select-large {
  height: 64px;
}

/* -----------------------------------------------------------------------------
 * Forms: Field.
 * ----------------------------------------------------------------------------- */

.field {
  margin-bottom: 10px;
}

span.required {
  color: var( --color-primary );
}

.field-error {
  display: none;
  position: absolute;
  width: 100%;
  margin-top: 8px;
  color: var( --color-primary );
  font-size: rem( 11px );
  text-align: right;

  @media (--hd) {
    text-align: left;
  }

  &.--active {
    display: block;
  }
}

/* -----------------------------------------------------------------------------
 * Forms: Honeypot Input
 * ----------------------------------------------------------------------------- */

.field[ data-hidden-input ],
.field[ data-hidden-input ] label,
.field[ data-hidden-input ] input {
  display: none;
  visibility: hidden;
  margin: 0;
  padding: 0;
  speak: none;
}
