/* -----------------------------------------------------------------------------
 *
 * Regions: Footer
 *
 * ----------------------------------------------------------------------------- */

.site-footer {
  padding: 60px 0;

  @media (--large) {
    padding: 76px 0 123px;
  }
}

/* -----------------------------------------------------------------------------
 * Site Footer Primary
 * ----------------------------------------------------------------------------- */

.site-footer-primary {
  @media (--large) {
    width: 100%;
    max-width: var( --grid-width );
    margin-right: auto;
    margin-left: auto;
    padding-right: var( --grid-gutter );
    padding-left: var( --grid-gutter );
  }
}

.footer-menu-primary .accordion {

  margin-top: 0;

  @media (--large) {
    display: flex;
    width: 100%;
  }

  & *::before,
  & *::after {
    @media (--large) {
      content: none;
    }
  }
}

.footer-menu-primary .accordion .accordion-item {

  @media (--large) {
    width: calc( 100% / 6 );
    border: none;
  }

  &::before {

    @media (--large) {
      content: none;
    }
  }
}

.footer-menu-primary .accordion .accordion-item:last-of-type {

  @media (--large) {
    margin: 0;
  }

  &::after {
    @media (--large) {
      content: none;
    }
  }
}

.footer-menu-primary .accordion-item.--active .accordion-title {
  &::after {
    @media (--large) {
      content: none;
    }
  }
}

.footer-menu-primary .accordion-item.--active .accordion-content {
  padding-top: 15px;
  padding-bottom: 30px;

  @media (--large) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.footer-menu-primary .accordion-header {
  @mixin mobile-container;
  background: var( --color-zircon );

  cursor: pointer;

  @media (--large) {
    max-width: none;
    padding: 0;
    padding-right: 0;
    padding-left: 0;
    cursor: default;
    appearance: none;
  }
}

.footer-menu-primary .accordion-title {
  color: var( --color-midnight-express );
  font-size: rem( 18px );
  line-height: lh( 18px, 22px );

  @media (--large) {
    margin-bottom: 20px;
  }
}

.footer-menu-primary .accordion-content {
  @mixin mobile-container;

  overflow: hidden;

  @media (--large) {
    display: block;
    max-height: 100% !important;
    margin-right: 38px;
    opacity: 1 !important;
  }
}

/* -----------------------------------------------------------------------------
 * Handles footer links inside of mobile mastfoot accordion:
 * accordion-content container would 'hide' when accordion item was closed, but
 * the contents inside would not. this caused links to overlay each other.
 * ----------------------------------------------------------------------------- */

.footer-menu-primary .accordion .accordion-item:last-of-type .accordion-content {

  @media (--large) {
    margin-right: 0;
  }
}

.footer-menu-primary li {
  font-size: rem( 14px );
  font-weight: 500;
  line-height: lh( 14px, 14px );
}

.footer-menu-primary .menu > li > a {
  display: block;
  padding: 10px 0;
  font-size: rem( 14px );
  font-weight: normal;
  line-height: lh( 16px, 20px );
  pointer-events: auto;
  color: var( --color-midnight-express );
}

/* -----------------------------------------------------------------------------
 * Site Footer Secondary
 * ----------------------------------------------------------------------------- */

.site-footer-secondary {
  @mixin mobile-container;
}

.site-footer-secondary .social {
  margin-top: 20px;
  margin-bottom: 0;

  @media (--hd) {
    margin-top: 0;
    float: right;
  }
}

/* -----------------------------------------------------------------------------
 * Footer Menu - Secondary
 * ----------------------------------------------------------------------------- */

.footer-menu-secondary {
  @media (--hd) {
    float: left;
  }
}

.footer-menu-secondary li {
  display: inline-block;
  width: 50%;
  font-size: rem( 12px );

  @media (--medium) {
    width: auto;
    margin-right: 25px;
  }
}

.footer-menu-secondary li > a {
  display: block;
  padding: 10px 10px 10px 0;
  font-weight: 500;
}

/* Cookie consent button style overrides */
li#ot-sdk-btn.ot-sdk-show-settings {
  color: var( --color-midnight-express );
  border: none;
  background: none;
  font-weight: 500;
  font-size: rem( 12px );
  padding: 10px 10px 10px 0;
  width: auto;

  &:hover {
    color: var( --color-midnight-express );
    text-decoration: underline;
    background: none;
  }
}
