/* -----------------------------------------------------------------------------
 *
 * Grid: Base
 *
 * Example Markup:
 *
 * <div class="g-row">
 *   <div class="g-col">
 *	   <p>Component or another grid layout, perhaps?</p>
 *	 </div>
 * </div>
 *
 * ----------------------------------------------------------------------------- */

.g-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: var( --grid-gutter-half-negative );
  margin-left: var( --grid-gutter-half-negative );

  & > .g-col {
    padding-right: var( --grid-gutter-half );
    padding-left: var( --grid-gutter-half );
  }
}

/* -----------------------------------------------------------------------------
 * Grid: Modifier Force Vertical Center
 * ----------------------------------------------------------------------------- */

.g-row--vertical-center {
  align-items: center;
  height: 100%;
}

/* -----------------------------------------------------------------------------
 * Grid: Modifier Space Between
 * ----------------------------------------------------------------------------- */

.g-row--space-between {
  justify-content: space-between;
}

/* -----------------------------------------------------------------------------
 * Grid: Modifier Pull Right
 * ----------------------------------------------------------------------------- */

.g-row--match-height {
  align-items: stretch;
}

/* -----------------------------------------------------------------------------
 * Grid: Modifier Reorder 2 Columns
 * ----------------------------------------------------------------------------- */

.g-row--reorder-2-col {
  > .g-col:first-child {
    order: 2;
  }

  > .g-col:last-child {
    order: 1;
  }
}
