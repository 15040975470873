/* -----------------------------------------------------------------------------
 *
 * Typography: General
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Typography: Paragraphs
 * ----------------------------------------------------------------------------- */

p {
  margin-top: 0;
  margin-bottom: 20px;
}

/* -----------------------------------------------------------------------------
 * Typography: Lead
 * ----------------------------------------------------------------------------- */

.lead {
  font-size: var( --lead-font-size );
  line-height: var( --lead-line-height );
}

/* -----------------------------------------------------------------------------
 * Typography: Error
 * ----------------------------------------------------------------------------- */

.error {
  font-size: var( --error-font-size );
  line-height: var( --error-line-height );
}

/* -----------------------------------------------------------------------------
 * Typography: Legal
 * ----------------------------------------------------------------------------- */

.legal {
  font-size: var( --legal-font-size );
  line-height: var( --legal-line-height );
}