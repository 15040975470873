/* -----------------------------------------------------------------------------
  *
  * Core
	*
	* These are styles of the main website elements - body, input, button, ul, ol, etc.
	* In this section, we use mainly HTML tags and attribute selectors, in exceptional cases - classes
	* (for example, if you have JavaScript-style selections);
  *
  * ----------------------------------------------------------------------------- */

html {
  scroll-padding-top: 53px;
}

body {
  color: var( --color-midnight-express );
  font-family: var( --font-primary );
  font-size: 16px;
  line-height: 1.5;
}

.grecaptcha-badge {
  display: none !important;
}

html[ data-nav-open='true' ] #kampyleButtonContainer {

  @media (--medium-max) {
    display: none;
  }
}

.content-area {
  min-height: 1000px;

  @media (--medium) {
    min-height: 1500px;
  }

  @media (--large) {
    min-height: 1750px;
  }
}

#kampyleButtonContainer img[alt="Feedback"] {
  @media (--large) {
    width: 33px;
    height: 122px;
  }
}
