/* -----------------------------------------------------------------------------
 *
 * Utilities: Utilities
 *
 * ----------------------------------------------------------------------------- */

@define-mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
