/* -----------------------------------------------------------------------------
 *
 * Grid: Columns
 *
 * ----------------------------------------------------------------------------- */

.g-col {
  position: relative;
  width: 100%;
  min-width: 0;
}
