/* -----------------------------------------------------------------------------
 *
 * Components: Sliders
 *
 * ----------------------------------------------------------------------------- */


/* -----------------------------------------------------------------------------
 * Swiper
 * ----------------------------------------------------------------------------- */

.swiper {
  max-width: 100%;
}

/* -----------------------------------------------------------------------------
  * Slider
  * ----------------------------------------------------------------------------- */

.slider {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-bottom: 60px;
  background-color: var( --color-white );
}

.slide {
  display: none;
  margin: 0;
  padding: 8px;
}

.slide:first-child {
  display: block;
}
