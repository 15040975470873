/* -----------------------------------------------------------------------------
 *
 * Components: Social
 *
 * ----------------------------------------------------------------------------- */

.social {
  display: table;
  margin-top: 40px;
  margin-bottom: 40px;
}

/* -----------------------------------------------------------------------------
* Social Menu
* ----------------------------------------------------------------------------- */

.social-menu {
  display: table;
  font-size: 0;
  letter-spacing: 0;
  line-height: 0;
}

ul.social-menu > li {
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

.social-menu li:last-child {
  margin-right: 0;
}

.social-menu a {
  display: block;
  padding: 6px;
  color: var( --color-white );
  text-decoration: none;
}

.social-menu a::before {
  position: relative;
  top: -1px;
  font-size: rem( 12px );
  line-height: 1;
}
