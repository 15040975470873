/* -----------------------------------------------------------------------------
 *
 * List: General
 *
 * ----------------------------------------------------------------------------- */

ul {
  margin-top: 0;
  margin-bottom: 20px;
  padding-left: 32px;
}

li {
  margin-bottom: 8px;
}

ul ul {
  margin-top: 8px;
  margin-bottom: 0;
}
